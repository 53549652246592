<template>
  <div class="content">
    <div class="shuju">
      <h2>数据融合与共享</h2>
      <div class="message">
        支持多种物联网设备的数据接入，如监控设备、消防设备、电梯设备、闸口设备等；也能直接从政府、企业的各类数据平台拉取数据，所聚合的数据，可以快速共享到其他平台。
      </div>
      <div class="imgs">
        <div class="son_img img1">
          <!-- <img src="../../../../assets/image/fbiImg/zakou.png" alt="" /> -->
          <div class="sebei_one">闸口设备</div>
        </div>
        <div class="son_img img2">
          <!-- <img src="../../../../assets/image/fbiImg/jiankong.png" alt="" /> -->
          <div class="sebei_two">监控设备</div>
        </div>
        <div class="son_img img3">
          <!-- <img src="../../../../assets/image/fbiImg/dianti.png" alt="" /> -->
          <div class="sebei_one">电梯设备</div>
        </div>
        <div class="son_img img4">
          <!-- <img src="../../../../assets/image/fbiImg/jingai.png" alt="" /> -->
          <div class="sebei_two">井盖设备</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 20px;
}
.content {
  padding: 0 16%;
  min-height: calc(100vh - 317px);
  .shuju {
    margin-top: 30px;
    .message {
      margin-top: 10px;
    }
    .imgs {
      margin-top: 40px;
      padding: 0 2%;
      display: flex;
      justify-content: space-between;
      .son_img {
        width: 15.5vw;
        height: 15.5vw;
        position: relative;
        margin: 30px 0;
        .sebei_one,
        .sebei_two {
          position: absolute;
          bottom: -15%;
          left: 33%;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
    }
  }
  .img1 {
    background: url("../../../../assets/image/fbiImg/zakou.png") no-repeat;
    background-size: contain;
  }
  .img2 {
    background: url("../../../../assets/image/fbiImg/jiankong.png") no-repeat;
    background-size: contain;
  }
  .img3 {
    background: url("../../../../assets/image/fbiImg/dianti.png") no-repeat;
    background-size: contain;
  }
  .img4 {
    background: url("../../../../assets/image/fbiImg/jingai.png") no-repeat;
    background-size: contain;
  }
}
</style>
